import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { Spacer } from "@/components/Spacer";

const AuthorPage: React.FC<PageProps<Queries.AuthorListQuery>> = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo title="Author" />
      <div className="prose max-w-full pt-12 pb-8">
        <h1 className="md:text-6xl">Author</h1>
      </div>

      <Spacer size={20} />

      <div className="grid  grid-cols-2 gap-4 md:grid-cols-3">
        {data.allContentfulAuthor.nodes.map((node) => (
          <React.Fragment key={node.slug}>
            {node.icon?.gatsbyImageData && (
              <Link to={`/author/${node.slug}`} className="group">
                <div className="rounded shadow transition-shadow group-hover:shadow-lg">
                  <GatsbyImage
                    image={node.icon?.gatsbyImageData}
                    alt={node.name ?? ""}
                    className="w-full rounded-t object-contain"
                  />
                  <h2 className="py-3 px-2 font-bold">{node.name}</h2>
                </div>
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AuthorList {
    allContentfulAuthor(sort: { fields: name }) {
      nodes {
        slug
        name
        icon {
          gatsbyImageData(formats: WEBP, placeholder: BLURRED, width: 300)
        }
      }
    }
  }
`;

export default AuthorPage;
